import React from 'react';
import { FaArrowUp } from 'react-icons/all';
import * as styles from './Footer.module.scss';
import { Link } from 'gatsby';

const Footer = ({showUp = true}) => (
    <div className={styles.footer}>
        {showUp && <a onClick={() => window.scrollTo(0, 0)} className={styles.up}> <FaArrowUp /> Nahoru</a>}
        <br />
        <div className={styles.copyright}>
            <Link className={styles.copyrightLink} to="/">© {new Date().getFullYear()} Jan Hlavatý MgA.</Link>
        </div>
    </div>
);

export default Footer;
